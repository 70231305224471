import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import axios from "axios";

import './Bind.css';

function Bind() {
  const { t }  = useTranslation(['page']);
  const { code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios({
      method: 'GET',
      url: `https://api.bind.moe/${code}/lookup`
    })
    .then(({ data }) => {
      if (!data.success) {
        throw new Error(data.error.code);
      }

      let target = data.target;

      if (!target.includes('//')) {
        target = 'http://' + target;
      }

      window.location.href = target;
    })
    .catch(error => {
      const errorString = error.toString();
      let errorCode = "ERRUNK";
      if (/ERR[0-9]{3}/.test(errorString)) {
        const match = errorString.match(/(ERR[0-9]{3})/);
        errorCode = match[1];
      }
      navigate(`/error?error=${errorCode}`);
    });
  }, [code, navigate]);

  return (
    <div className='Bind'>
      <label>{ t('connectingLink') }</label>
    </div>
  );
}

export default Bind;