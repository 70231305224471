import { useState, useEffect, useRef, useCallback } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

import './Home.css';

import imageFire from './image/local_fire_department_black_24dp.svg';
import imageLink from './image/link_black_24dp.svg';

function Home() {
  const { t }  = useTranslation(['page']);
  const [textLink, setTextLink] = useState('');
  const [searchParams] = useSearchParams();
  const target = searchParams.get('target');
  const navigate = useNavigate();
  const searchBarRef = useRef(null);
  const inputRef = useRef(null);
  
  const createLink = useCallback((target) => {
    axios({
      method: 'POST',
      url: `https://api.bind.moe/?target=${encodeURIComponent(target)}`
    })
    .then(({ data }) => {
      if (!data.success) {
        throw new Error(data.error.code);
      }

      navigate(`/created?code=${data.code}`);
    })
    .catch(error => {
      const errorString = error.toString();
      let errorCode = "ERRUNK";
      if (/ERR[0-9]{3}/.test(errorString)) {
        const match = errorString.match(/(ERR[0-9]{3})/);
        errorCode = match[1];
      }
      navigate(`/error?error=${errorCode}`);
    });
  }, [navigate]);

  const createLinkFromTextLink = () => {
    if (textLink) {
      createLink(textLink);
    } else {
      toast.warning(t('emptySearchBar'), { autoClose: 2000 });
      searchBarRef.current.className = "search-bar active";
      inputRef.current.focus();
      setTimeout(() => {
        searchBarRef.current.className = "search-bar";
      }, 200);
    }
  }

  const handleTextLink = (event) => {
    setTextLink(event.target.value.trim());
  }

  const handleTextLinkEnter = (event) => {
    if (event.key === "Enter") {
      createLinkFromTextLink();
    }
  }

  useEffect(() => {
    if (target) {
      createLink(target);
    }
    inputRef.current.focus();
  }, [target, createLink]);

  return (
    <div className='Home'>
      <main>
        <div className='logo-box'>
          <img src={imageFire} alt="BIND.MOE Logo" />
          <label>BIND.MOE</label>
        </div>
        <div className='search-box'>
          <div className='search-bar' ref={searchBarRef}>
            <input
              type='text'
              placeholder='https://www.bind.moe/'
              value={textLink}
              onChange={handleTextLink}
              onKeyDown={handleTextLinkEnter}
              ref={inputRef}
            />
            <img src={imageLink} alt="link button" onClick={createLinkFromTextLink} />
          </div>
        </div>
        <div className='guide-box'>
          <Link to='/guide'>
            <label>
              <span>{ t('addBoomarkSign') }</span>
              <span className="material-icons-outlined">
                navigate_next
              </span>
            </label>
          </Link>
        </div>
      </main>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Home;