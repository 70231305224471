import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './page/Home';
import Created from './page/Created';
import Bind from './page/Bind';
import Guide from './page/Guide';
import Error from './page/Error';

import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/created" element={<Created />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/error" element={<Error />} />
        <Route path="/:code" element={<Bind />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;