import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './Error.css';

import imageFire from './image/local_fire_department_black_24dp.svg';

function Error() {
  const navigate = useNavigate();
  const { t }  = useTranslation(['page']);
  const [searchParams] = useSearchParams();
  let errorCode = searchParams.get('error');

  if (!errorCode || !/ERR[0-9]{3}/.test(errorCode)) {
    errorCode = "ERRUNK";
  }

  const goHome = () => {
    navigate('/');
  }

  return (
    <div className='Error'>
      <main>
        <div className='logo-box' onClick={goHome}>
          <img src={imageFire} alt="BIND.MOE Logo" />
          <label>BIND.MOE</label>
        </div>
        <div className='error-box'>
          <h1>ERROR</h1>
          <p>{ t(`${errorCode}Message`) }</p>
        </div>
      </main>
    </div>
  );
}

export default Error;