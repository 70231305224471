import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Guide.css';

import imageFire from './image/local_fire_department_black_24dp.svg';
import imageGuide1 from './image/guide/bind.me-bookmark-guide-1.png';
import imageGuide2 from './image/guide/bind.me-bookmark-guide-2.png';
import imageGuide3 from './image/guide/bind.me-bookmark-guide-3.png';
import imageGuide4 from './image/guide/bind.me-bookmark-guide-4.png';
import imageGuide5 from './image/guide/bind.me-bookmark-guide-5.png';


function Guide() {
  const navigate = useNavigate();
  const { t }  = useTranslation(['page']);
  const codeBoxRef = useRef(null);
  const codeString = 'javascript:window.open("https://www.bind.moe/?target="+encodeURIComponent(window.location.href));';

  const goHome = () => {
    navigate('/');
  }

  const copyCode = () => {
    navigator.clipboard.writeText(codeString)
    .then(() => {
      toast.success(t('copiedToClipboard'), { autoClose: 1000 });
      codeBoxRef.current.className = "code-box active";
      setTimeout(() => {
        codeBoxRef.current.className = "code-box";
      }, 200);
    });
  }

  return (
    <div className='Guide'>
      <main>
        <div className='logo-box' onClick={goHome}>
          <img src={imageFire} alt="BIND.MOE Logo" />
          <label>BIND.MOE</label>
        </div>
        <div className='guide-box'>
          <div className='guide-title'>
            <h2>{ t('guideTitle') }</h2>
          </div>
          <div className='guide-item left'>
              <div className='guide-image'>
                <img src={imageGuide1} />
              </div>
              <div className='guide-desc'>
                <label>
                  <p>
                    { t('guide1') }
                  </p>
                </label>
              </div>
          </div>
          <div className='guide-item right'>
              <div className='guide-image'>
                <img src={imageGuide2} />
              </div>
              <div className='guide-desc'>
                <label>
                  <p>
                    { t('guide2') }
                  </p>
                </label>
              </div>
          </div>
          <div className='guide-item left'>
              <div className='guide-image'>
                <img src={imageGuide3} />
              </div>
              <div className='guide-desc'>
                <label>
                  <p>
                    { t('guide3') }
                  </p>
                </label>
              </div>
          </div>
          <div className='guide-item right'>
              <div className='guide-image'>
                <img src={imageGuide4} />
              </div>
              <div className='guide-desc'>
                <label>
                  <p>
                    { t('guide4') }
                  </p>
                </label>
                <div className='code-box' ref={codeBoxRef} onClick={copyCode}>
                  <code>
                    { codeString }
                  </code>
                </div>
              </div>
          </div>
          <div className='guide-item left'>
              <div className='guide-image'>
                <img src={imageGuide5} />
              </div>
              <div className='guide-desc'>
                <label>
                  <p>
                    { t('guide5') }
                  </p>
                </label>
              </div>
          </div>
        </div>
      </main>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Guide;