import { useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Created.css';

import imageFire from './image/local_fire_department_black_24dp.svg';
import imageCopy from './image/content_copy_black_24dp.svg';

function Created() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const link = 'http://bind.moe/' + code;
  const navigate = useNavigate();
  const { t }  = useTranslation(['page']);
  const linkBarRef = useRef(null);

  const goHome = () => {
    navigate('/');
  }

  const copyLink = () => {
    navigator.clipboard.writeText(link)
    .then(() => {
      toast.success(t('copiedToClipboard'), { autoClose: 1000 });
      linkBarRef.current.className = "link-bar active";
      setTimeout(() => {
        linkBarRef.current.className = "link-bar";
      }, 200);
    });
  }

  return (
    <div className='Created'>
      <main>
        <div className='logo-box' onClick={goHome}>
          <img src={imageFire} alt="BIND.MOE Logo" />
          <label>BIND.MOE</label>
        </div>
        <div className='link-box'>
          <div className='link-bar' ref={linkBarRef}>
            <input type='text' value={link} readOnly="readOnly" />
            <img src={imageCopy} alt="link button" onClick={copyLink}/>
          </div>
        </div>
      </main>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Created;