import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en';
import ko from './locales/ko';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
        en,
        ko
    },
    fallbackLng: "en",
    ns: ['page'],
    interpolation: {
      escapeValue: false
    },
    detection: { order: ['localStorage', 'navigator'] }
  });

  export default i18n
